import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import styled from "styled-components"
import ContactForm from "../components/ContactForm"
import { Button } from "@mui/material"

const ContactPage = () => (
  <Layout>
    <Seo title="Contact" />
    <article className="flex flex-col max-w-xl">
      <section className="col-span-4">
        <h1>Contact me</h1>
        <p>If you want to inquire about a consultation, about EA or something else, send a message to <a href="mailto:kim@plutoastrology.net">kim@plutoastrology.net</a>.</p>
        {/* <ContactForm /> */}
      </section>
    </article>
    {/* <SocialMedia className="mt-8 col-span-3 flex flex-col justify-start p-2">
      <section className="flex flex-col mx-2 sm:mx-4">
        <h3 className="mb-6">You can also reach me at:</h3>
        <a className="icon-section" href="https://www.facebook.com/plutoastrologen">
          <div className="flex items-center">
            <div className="fa fa-facebook"></div>
            <p>my Swedish Facebook page</p>
          </div>
          <span>➤</span>
        </a>
        <a className="icon-section" href="https://www.instagram.com/theplutoastrologer/">
          <div className="flex items-center">
            <div className="fa fa-instagram"></div>
            <p>Instagram</p>
          </div>
          <span>➤</span>
        </a>
        <a className="icon-section" href="mailto:kim.johan.lindgren@gmail.com">
          <div className="flex items-center">
            <div className="fa fa-google"></div>
            <p>my personal Gmail</p>
          </div>
          <span>➤</span>
        </a>
      </section>
    </SocialMedia> */}
  </Layout>
)

const SocialMedia = styled.section`
  background: var(--main-gray);
  .icon-section {
    text-decoration: none;
    color: var(--main-black);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.8rem;
    border-radius: 999px;
    max-width: 550px;
    background: linear-gradient(90deg, rgba(206,204,232,0.7) 0%, rgba(216,216,233,0.7) 100%);
    :hover {
      background: linear-gradient(90deg, rgba(206,204,232,1) 0%, rgba(216,216,233,1) 100%);
    }
    p {
      padding-top: 0.3rem;
      font-family: "CrimsonTextBold";
      margin: 0;
    }
    p, span {
      font-size: 1rem;
      @media screen and (min-width: 640px) {
        font-size: 1.4rem;
    }
  }
  span {
    padding-right: 1rem;
  }
    
  }
  h3 {
    margin-top: .5rem;
    font-family: "CrimsonTextBold";
    font-size: 22px;
    color: #2e559a;
    @media screen and (min-width: 640px) {
      font-size: 28px; 
    }
  }
  hr {
    /* width: 20%;
    margin-top: .5rem; */
  }
  .fa {
  padding: 10px;
  font-size: 40px;
  width: 60px;
  text-align: center;
  text-decoration: none;
  margin-right: 1.5rem;
  border-radius: 999px;
}

.fa:hover {
    opacity: 0.7;
}

.fa-facebook {
  background: #3B5998;
  color: white;
}

.fa-instagram {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  color: white;
}

.fa-google {
  background: #dd4b39;
  color: white;
}
`

export default ContactPage
