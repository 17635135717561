import React from "react"
import styled from "styled-components"
import { TextField, Button } from "@mui/material"
import { useForm } from "react-hook-form"
import { createContact } from '../services/contacts';
import { Link } from 'gatsby'

const EMAIL_REGEX = /.+@.+\.[A-Za-z]+$/
const REQUIRED_MESSAGE = "This field is required."
const VALID_EMAIL_MESSAGE = "Must be a valid email."

const ContactForm = () => {
  const [submit, setSubmit] = React.useState(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" })

  const sendContactHandler = data => {
    createContact(data)
    reset()
    setSubmit(true)
  }

  return (
    <div>
      {submit ?
      <>
        <p>Thanks for contacting me. I will get back to you as soon as I can!</p>
        <p><Link to="/">Back to home page</Link></p>
      </>
        :
        <>
          <p>If you want to inquire about a consultation, about EA or something else, send me a message in the form below.</p>
          <form className="contact-form" onSubmit={handleSubmit(sendContactHandler)}>
            <FieldWrapper>
              <TextField
                label="Name"
                name="name"
                size="small"
                {...register("name", { required: true })}
                error={!!errors.name}
                helperText={!!errors.name ? REQUIRED_MESSAGE : ""}
                fullWidth
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextField
                label="Email"
                name="email"
                size="small"
                {...register("email", { pattern: EMAIL_REGEX, required: true })}
                error={!!errors.email}
                helperText={!!errors.email ? VALID_EMAIL_MESSAGE : ""}
                fullWidth
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextField
                label="Subject"
                name="subject"
                size="small"
                {...register("subject", { required: true })}
                error={!!errors.subject}
                helperText={!!errors.subject ? REQUIRED_MESSAGE : ""}
                fullWidth
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextField
                label="Message"
                name="message"
                size="small"
                rows={7}
                {...register("message", { required: true })}
                error={!!errors.message}
                helperText={!!errors.message ? REQUIRED_MESSAGE : ""}
                multiline
                fullWidth
              />
            </FieldWrapper>
            <ButtonWrapper>
              <Button
                type="submit"
                variant="outlined"
                color="secondary"
                disabled={!isValid}
              >
                Send
              </Button>
            </ButtonWrapper>
          </form>
        </>
      }
    </div>
  )
}

export default ContactForm

const FieldWrapper = styled.div`
  margin: 12px 0px;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`